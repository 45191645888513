import React, { useState, useRef, useEffect } from "react";
import {
  FaChevronUp,
  FaChevronDown,
  FaPlus,
  FaEllipsisV,
  FaPencilAlt,
} from "react-icons/fa";
import cn from "@/utils/cn";
import TextComponent from "@/app/_components/TextComponent";
import {
  DashboardDropdownIcon,
  DashboardEditIcon,
} from "@/assets/svg-icons/svg-icons";
import { useRouter, usePathname } from "next/navigation";

interface DropdownItem {
  id: string;
  name: string;
  type?: "template" | "shared";
}

interface DropdownProps {
  title: string;
  selectedItemId: string | null;
  items: DropdownItem[];
  onSelect: (id: string) => void;
  onAdd?: (name: string) => string;
  onUpdate?: (id: string, name: string) => void;
  onDelete?: (id: string) => void;
  IconOpen: any;
  IconClosed: any;
  placeholder?: string;
  minNameLength?: number;
  showCreateOption?: boolean;
  invertColors?: boolean;
  className?: string;
  variant?: "default" | "simple";
  label?: string;
  labelClassName?: string;
  buttonClassName?: string;
  modalPosition?: "top-left" | "top-right" | "bottom-left" | "bottom-right";
  modalClassName?: string;
  error?: boolean;
}

const ReusableDropdown: React.FC<DropdownProps> = ({
  title,
  selectedItemId,
  items,
  onSelect,
  onAdd,
  onUpdate,
  onDelete,
  IconOpen,
  IconClosed,
  minNameLength = 1,
  showCreateOption,
  invertColors,
  className = "",
  variant = "default",
  label = "",
  labelClassName = "",
  buttonClassName = "",
  modalPosition = "bottom-right",
  modalClassName = "",
  error,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isCreatingNew, setIsCreatingNew] = useState(false);
  const [newItemName, setNewItemName] = useState("");
  const [editingItemId, setEditingItemId] = useState<string | null>(null);
  const [editingItemName, setEditingItemName] = useState("");
  const [showOptionsForId, setShowOptionsForId] = useState<string | null>(null);
  const [hoveredItemId, setHoveredItemId] = useState<string | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const optionsModalRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const router = useRouter();
  const pathName = usePathname();

  const selectedItem =
    items.find((item) => item.id === selectedItemId)?.name || "";

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        resetState();
      }

      // Close options modal when clicking outside
      if (
        optionsModalRef.current &&
        !optionsModalRef.current.contains(event.target as Node) &&
        !(event.target as HTMLElement).closest(".options-trigger")
      ) {
        setShowOptionsForId(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if ((isCreatingNew || editingItemId) && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isCreatingNew, editingItemId]);

  const resetState = () => {
    setIsOpen(false);
    setIsCreatingNew(false);
    setNewItemName("");
    setShowOptionsForId(null);
    setEditingItemId(null);
    setEditingItemName("");
    setHoveredItemId(null);
  };

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleItemSelect = (id: string) => {
    onSelect(id);
    setShowOptionsForId(null);
    resetState();
  };

  const handleCreateNewClick = () => {
    if (variant === "default" && onAdd) {
      setIsCreatingNew(true);
      setNewItemName("");
      setShowOptionsForId(null);
    }
  };

  const handleNewItemNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewItemName(e.target.value);
  };

  const handleNewItemSubmit = () => {
    if (
      onAdd &&
      newItemName.trim().length >= minNameLength &&
      newItemName.trim() !== title
    ) {
      const newItemId = onAdd(newItemName.trim());
      handleItemSelect(newItemId);
      setIsCreatingNew(false);
      setNewItemName("");
    }
  };

  const handleEditItem = (
    id: string,
    currentName: string,
    e: React.MouseEvent,
  ) => {
    e.stopPropagation();
    if (variant === "default" && onUpdate) {
      setEditingItemId(id);
      setEditingItemName(currentName);
      setShowOptionsForId(null);
    }
  };

  const handleEditItemNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingItemName(e.target.value);
  };

  const handleEditItemSubmit = () => {
    if (
      editingItemId &&
      editingItemName.trim().length >= minNameLength &&
      onUpdate
    ) {
      onUpdate(editingItemId, editingItemName.trim());
      setEditingItemId(null);
      setEditingItemName("");
    }
  };

  const handleDeleteItem = (id: string) => {
    if (onDelete) {
      onDelete(id);
      setShowOptionsForId(null);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent, isEditing: boolean) => {
    if (e.key === "Enter") {
      isEditing ? handleEditItemSubmit() : handleNewItemSubmit();
    }
  };

  const handleOptionsClick = (e: React.MouseEvent, id: string) => {
    e.stopPropagation();
    setShowOptionsForId(showOptionsForId === id ? null : id);
  };

  const truncateText = (text: string, maxLength: number) => {
    return text?.length > maxLength
      ? text?.substring(0, maxLength) + "..."
      : text;
  };

  return (
    <div className={`relative select-none  ${className}`} ref={dropdownRef}>
      {label && (
        <TextComponent
          type="h4"
          className={cn(
            "mb-[6.5px] font-medium 2xl:mb-[10px] text-[#444A6D]",
            labelClassName,
          )}
        >
          {label}
        </TextComponent>
      )}
      <div
        onClick={toggleDropdown}
        className={cn(
          "flex items-center space-x-2 justify-between px-4 py-2 h-[60px] focus:outline-none cursor-pointer",
          "xl:text-[10.6px]  xl:h-[60px] 2xl:text-base 2xl:rounded-2xl xl:rounded-[10.6px]",
          "h-[30px] text-[8px] flex rounded-lg",
          "2xl:w-full w-full xl:w-full md:w-full",
          invertColors ? "border" : "",
          // dont change button color for simple dropdown, only invert it for edit dashboard and dashboard home
          variant != "simple" &&
            (invertColors
              ? isOpen
                ? "bg-[#F9FAFB] text-[#05004E]"
                : "bg-kd-lte-blue text-white"
              : isOpen
                ? "bg-[#F9FAFB] text-[#05004E] border border-kd-lte-blue"
                : "bg-[#F9FAFB] text-[#05004E]"),
          variant === "simple" && "bg-[#F9FAFB]",
          error ? "border border-[#FA5A7D]" : "",
          buttonClassName,
        )}
      >
        <div
          className={cn("size-3 xl:size-4", variant === "simple" && "hidden")}
        >
          {invertColors ? (
            isOpen ? (
              <IconOpen className={"size-full"} />
            ) : (
              // <IconClosed className={"size-full"} />
              <DashboardDropdownIcon className="size-full" />
            )
          ) : isOpen ? (
            <IconClosed className={"size-full"} />
          ) : (
            <IconOpen className={"size-full"} />
          )}
        </div>
        <TextComponent type="h4" className="grow text-nowrap text-left">
          <div className="hidden 2xl:block">
            {selectedItem ? (
              truncateText(selectedItem, 15)
            ) : (
              <span className="text-[#8E8E93]">{title}</span>
            )}
          </div>
          <div className="block 2xl:hidden">
            {selectedItem ? (
              truncateText(selectedItem, 13)
            ) : (
              <span className="text-[#8E8E93]">{title}</span>
            )}
          </div>
        </TextComponent>
        {isOpen ? (
          <FaChevronUp
            className={cn(
              variant !== "simple" &&
                (!invertColors
                  ? isOpen
                    ? "text-kd-lte-blue"
                    : "text-kd-lte-blue"
                  : isOpen
                    ? "text-kd-lte-blue"
                    : "text-white"),
            )}
          />
        ) : (
          <FaChevronDown
            className={cn(
              variant !== "simple"
                ? !invertColors
                  ? "text-kd-lte-blue"
                  : "text-white"
                : "text-[#05004E]",
            )}
          />
        )}
      </div>

      {isOpen && (
        <div
          className={cn(
            "absolute z-30 mt-2 xl:w-[280px] w-[140px]",
            modalPosition === "bottom-right" ? "right-0" : "left-0",
          )}
        >
          <div className="rounded-2xl border border-[#336dff] bg-white shadow-lg scrollbar-hide xl:max-h-80">
            <div className="max-h-80 overflow-y-scroll rounded-2xl scrollbar-hide">
              <div className="">
                {variant === "default" && showCreateOption && onAdd && (
                  <div
                    className="sticky top-0 z-30 flex cursor-pointer items-center border-b-2 border-dashed bg-white p-4 py-6 hover:bg-[#EDF2F6]"
                    onClick={handleCreateNewClick}
                  >
                    <div className="mr-2.5 flex size-4 items-center justify-center rounded-full border-[1.4px] border-kd-lte-blue p-0.5">
                      <FaPlus className="text-kd-lte-blue" size={10} />
                    </div>
                    {isCreatingNew ? (
                      <input
                        ref={inputRef}
                        type="text"
                        value={newItemName}
                        onChange={handleNewItemNameChange}
                        onKeyDown={(e) => handleKeyPress(e, false)}
                        className="w-full bg-transparent text-xs text-gray-700 focus:outline-none"
                        placeholder={`Enter ${title.toLowerCase()} name`}
                      />
                    ) : (
                      <TextComponent
                        type="caption"
                        className=" text-kd-lte-blue"
                      >
                        Create New {title}
                      </TextComponent>
                    )}
                  </div>
                )}

                {/* {variant === "default" && showCreateOption && (
                  <div className="border-b border-dashed border-gray-300"></div>
                )} */}

                {items.map((item) => (
                  <div
                    key={item.id}
                    className="relative flex cursor-pointer items-center justify-between p-4 hover:bg-[#EDF2F6] 2xl:py-6"
                    onMouseEnter={() => setHoveredItemId(item.id)}
                    onMouseLeave={() => setHoveredItemId(null)}
                    onClick={() => handleItemSelect(item.id)}
                  >
                    <div className="flex grow items-center">
                      {item.id === selectedItemId ? (
                        <div className="mr-2.5 flex size-2.5 rounded-full border-[1.5px] border-kd-lte-blue xl:size-4">
                          <div className="m-auto size-1 rounded-full bg-kd-lte-blue xl:size-2"></div>
                        </div>
                      ) : (
                        <div className="mr-2.5 size-2.5 rounded-full border-[1.5px] border-kd-lte-blue xl:size-4"></div>
                      )}

                      {editingItemId === item.id &&
                      variant === "default" &&
                      onUpdate ? (
                        <input
                          ref={inputRef}
                          type="text"
                          value={editingItemName}
                          onChange={handleEditItemNameChange}
                          onKeyDown={(e) => handleKeyPress(e, true)}
                          onClick={(e) => e.stopPropagation()}
                          className="w-full bg-transparent text-xs text-gray-700 focus:outline-none"
                        />
                      ) : (
                        <TextComponent type="caption">
                          {truncateText(item.name, 23)}{" "}
                          {item?.type === "shared" && `(${item?.type})`}
                        </TextComponent>
                      )}
                    </div>

                    {variant === "default" && onUpdate && onDelete && (
                      <div
                        className="flex items-center"
                        onClick={(e) => e.stopPropagation()}
                      >
                        {hoveredItemId === item.id &&
                          editingItemId !== item.id && (
                            <button
                              onClick={(e) =>
                                handleEditItem(item.id, item.name, e)
                              }
                              className="mr-2 text-gray-400 hover:text-gray-600"
                            >
                              {/* <FaPencilAlt size={12} /> */}
                              <DashboardEditIcon />
                            </button>
                          )}
                        <button
                          onClick={(e) => handleOptionsClick(e, item.id)}
                          className="text-gray-400 hover:text-gray-600"
                        >
                          <FaEllipsisV size={12} />
                        </button>

                        {showOptionsForId === item.id && (
                          <div className="fixed z-20" ref={optionsModalRef}>
                            <div
                              className="absolute right-16 top-7 w-[123px] overflow-hidden rounded-lg border border-kd-lte-blue bg-white shadow-lg"
                              style={{
                                transform: "translateX(calc(100% + 0.5rem))",
                                marginTop: "-0.5rem",
                              }}
                            >
                              <div className="flex flex-col">
                                {pathName !== "/dashboard/edit" && (
                                  <button
                                    onClick={(e) =>
                                      router.push("/dashboard/edit")
                                    }
                                    className="px-4 py-2 text-left text-xs text-gray-700 hover:bg-gray-100"
                                  >
                                    Edit
                                  </button>
                                )}
                                <button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleItemSelect(item.id);
                                  }}
                                  className="px-4 py-2 text-left text-xs text-gray-700 hover:bg-gray-100"
                                >
                                  Select
                                </button>
                                <div className="border-t border-dashed border-gray-200"></div>
                                <button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleDeleteItem(item.id);
                                  }}
                                  className="px-4 py-2 text-left text-xs text-[#FA5A7D] hover:bg-gray-100"
                                >
                                  Delete
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReusableDropdown;
